/* Css Base Line */
body {
  margin: 0;
  padding: 0;
  color: #101828;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  font-size: 1rem;
  background-color: #fff;
}
::-webkit-scrollbar {
  width: 4px;
  height: 4px;
  cursor: pointer;
}
::-webkit-scrollbar-thumb {
  cursor: pointer;
  background: #d7d7d7;
  width: 4px;
  height: 122px;
  border-radius: 8px;
}

::-webkit-scrollbar-thumb:hover {
  cursor: pointer;
  background: #d9d9d9;
}
::-webkit-scrollbar-button {
  display: none !important;
}

.leaflet-div-icon {
  background: none !important;
  border: none !important;
}

.leaflet-container {
  /* background-color: #fff !important; */
  background: linear-gradient(-90deg, rgba(0, 0, 0, 0.02) 1px, transparent 0),
    linear-gradient(rgba(0, 0, 0, 0.02) 1px, transparent 0),
    linear-gradient(-90deg, rgba(0, 0, 0, 0.03) 1px, transparent 0),
    linear-gradient(rgba(0, 0, 0, 0.03) 1px, transparent 0),
    linear-gradient(transparent 4px, #fff 0, #fff 97px, transparent 0),
    linear-gradient(-90deg, #fff 1px, transparent 0),
    linear-gradient(-90deg, transparent 4px, #fff 0, #fff 97px, transparent 0),
    linear-gradient(#fff 1px, transparent 0), #fff !important;
  background-size:
    10px 10px,
    10px 10px,
    100px 100px,
    100px 100px,
    100px 100px,
    100px 100px,
    100px 100px,
    100px 100px !important;
}
/* Slick slider for dashboard */
.slick-slider,
.slick-initialized {
  display: flex !important;
  justify-content: center;
  align-items: center;
  gap: 12px;
}

/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 30px;
}

.slick-dots {
  position: absolute;
  bottom: -20px;

  display: block;

  width: 100%;
  padding: 0;
  margin: 0;

  list-style: none;

  text-align: center;
}
.slick-dots li {
  position: relative;

  display: inline-block;

  width: 20px;
  height: 20px;
  margin: 0 5px;
  padding: 0;

  cursor: pointer;
}
.slick-dots li button {
  font-size: 0;
  line-height: 0;

  display: block;

  width: 20px;
  height: 20px;
  padding: 5px;

  cursor: pointer;

  color: transparent;
  border: 0;
  outline: none;
  background: transparent;
}
.slick-dots li button:hover,
.slick-dots li button:focus {
  outline: none;
}
.slick-dots li button:hover:before,
.slick-dots li button:focus:before {
  opacity: 1;
}
.slick-dots li button:before {
  font-family: 'slick';
  font-size: 30px;
  line-height: 20px;

  position: absolute;
  top: 0;
  left: 0;

  width: 20px;
  height: 20px;

  content: '•';
  text-align: center;

  opacity: 0.25;
  color: #3dc4c4;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.slick-dots li.slick-active button:before {
  opacity: 0.95;
  color: #3dc4c4;
}

.leaflet-touch .leaflet-control-layers-toggle {
  width: 20px !important;
  height: 20px !important;
  border: unset;
}

/* Leaflet icon styles */
.cos-div-icon__name {
  min-width: 50px;
  font-size: 7px;
  font-weight: 500;
  background: #3dc4c4;
  padding: 2px 4px;
  border-radius: 3px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  color: #fff;
  text-align: center;
  font-family: 'Poppins';
  transform: translateX(-8px);
  /* transition: 1.5s all; */
}

/* Date Picker Styles */

.react-daterange-picker__wrapper {
  border: 1px solid #ebebeb !important;
  border-radius: 4px;
  padding: 5px;
}
.react-daterange-picker__calendar {
  z-index: 100 !important;
}

.react-calendar {
  border: 1px solid #ebebeb !important;
  line-height: 1.9025em !important;
  font-family: 'Poppins' !important;
}

.react-calendar__tile--now {
  border: unset !important;
  font-weight: 700 !important;
  background-color: unset !important;
  color: #3dc4c4 !important;
}

.react-calendar__tile--now:enabled:focus {
  border: unset !important;
  font-weight: 700 !important;
  background-color: #d7d7d7 !important;
  color: #3dc4c4 !important;
}

.react-calendar__tile--active {
  background-color: #d7d7d7 !important;
}

.react-calendar__tile--hasActive {
  background-color: #d9d9d9 !important;
}
.react-daterange-picker__inputGroup__input:invalid {
  background-color: transparent !important;
}
.react-calendar__tile:disabled {
  background-color: #d9d9d9;
  color: #d9d9d9;
}

.react-daterange-picker__range-divider {
  margin-left: 6px;
  margin-right: 6px;
}
